import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/files";

export async function listFiles(collection: Learnlink.File.DocumentCollectionRef, uid: string): Promise<Learnlink.File.Reference[]> {
  const fileRes = await axios.get<Learnlink.File.Reference[]>(`${ENDPOINT}/${collection}/${uid}`);
  return fileRes.data;
}

export async function deleteFile(collection: Learnlink.File.DocumentCollectionRef, uid: string, fileuid: string): Promise<void> {
  await axios.delete(`${ENDPOINT}/${collection}/${uid}/${fileuid}`);
}

export async function uploadFiles(formData: FormData, collection: Learnlink.File.DocumentCollectionRef, uid: string): Promise<Learnlink.File.Reference[]> {
  const uploadRes = await axios.post(
    `${ENDPOINT}/upload/${collection}/${uid}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  return uploadRes.data;
}
