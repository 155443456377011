


























import { uploadFiles } from "@/api/file-service";
import Learnlink from "@learnlink/interfaces";
import Vue, { PropType } from "vue";

  interface HTMLInputEvent extends Event {
    dataTransfer: HTMLInputElement & DataTransfer;
    target: HTMLInputElement & EventTarget;
  }

export default Vue.extend({
  name: "Uploader",
  props: {
    collection: {
      type: String as PropType<Learnlink.File.DocumentCollectionRef>,
      required: true,
    },
    uid: {
      type: String as PropType<string>,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async handleFileInput(e: HTMLInputEvent) {
      const fieldName = e.target.name;
      const fileList = e.target.files;
      const formData = new FormData();

      if (!fileList || !fileList.length) return;

      Array.from(fileList).forEach((file) => {
        formData.append(fieldName, file, file.name);
      });

      try {
        this.loading = true;
        await uploadFiles(formData, this.$props.collection, this.$props.uid);
        this.$emit("ok");
      }
      catch (error) {
        this.$emit("error", error);
      }
      finally {
      }
      this.loading = false;
    },
  },
});
