
















import { updateProfile } from "@/api/profile-service";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, PropType, reactive, onMounted } from "@vue/composition-api";

const weekdays = [
  {
    value: "monday",
    text: "Mandager",
  },
  {
    value: "tuesday",
    text: "Tirsdager",
  },
  {
    value: "wednesday",
    text: "Onsdager",
  },
  {
    value: "thursday",
    text: "Torsdager",
  },
  {
    value: "friday",
    text: "Fredager",
  },
  {
    value: "saturday",
    text: "Lørdager",
  },
  {
    value: "sunday",
    text: "Søndager",
  },
];

export default defineComponent({
  name: "WeekdayPicker",
  props: {
    seller: {
      type: Object as PropType<Learnlink.Seller.default>,
      required: true
    },
    label: {
      type: String as PropType<string>,
      default: ""
    }
  },
  setup(props) {
    const vm = reactive({
      loading: false,
      selection: [] as Learnlink.Type.Weekday[],
    });

    onMounted(() => {
      vm.selection = props.seller.profile.weekdays || [];
    });

    async function save() {
      vm.loading = true;

      const updatedSellerProfile = await updateProfile({
        uid: props.seller.user.uid,
        weekdays: vm.selection,
      });
      props.seller.profile.weekdays = updatedSellerProfile.weekdays;

      vm.loading = false;
    }

    return {
      weekdays,
      daysAndTimes: weekdays,
      save,
      vm
    };
  },
});
