






























































































































































import { createInvoice, update } from "@/api/balance-order-service";
import Loader from "@/components/Loader.vue";
import CancelOrderDialog from "@/components/orders/CancelBalanceOrderDialog.vue";
import HandleOrderDialog from "@/components/orders/HandleBalanceOrderDialog.vue";
import { copyToClipboard } from "@/helpers/copyToClipboard";
import { sanitizeError } from "@/helpers/errors";
import Moment from "@/services/moment";
import store from "@/store/store";
import { computed, defineComponent, inject, onMounted, PropType, reactive } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "OrderCard",
  components: { CancelOrderDialog, HandleOrderDialog, Loader },
  props: {
    order: {
      type: Object as PropType<Learnlink.BalanceOrder.default.Order>,
      required: true,
    },
    index: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      cancelOrderDialogVisible: false,
      handleOrderDialogVisible: false,
      loading: false,
      invoiceItemLoading: false,
      orderNote: "",
      // studentNote: "",
    });

    const orderButtonText = computed(() => {
      return props.order.invoiceItemID
        ? props.order.invoiceID
          ? "Fakturert"
          : "Faktureres"
        : "Fakturer";
    });

    onMounted(() => {
      vm.loading = true;
      // vm.studentNote = props.order.student?.note;
      vm.orderNote = props.order.note;
      vm.loading = false;
    });

    const readableOrderDate = computed(() => Moment.unix(props.order.created).format("lll"));
    const openEditCustomerDialog = inject<(customerUID: string) => void>("openEditCustomerDialog");
    const updateOrderInject = inject<(uid: string, order: Partial<Learnlink.BalanceOrder.default.Order>|null) => void>("updateBalanceOrder");

    async function create(): Promise<void> {
      vm.invoiceItemLoading = true;
      try {
        const order = await createInvoice(props.order.ID);
        updateOrderInject && updateOrderInject(props.order.ID, order);
        await store.dispatch("displayAlert", {
          color: "success",
          message: "Faktureres! 🥳",
        });
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: sanitizeError(e).message,
        });
      }
      vm.invoiceItemLoading = false;
    }

    async function updateOrderNote(): Promise<void> {
      try {
        await update(props.order.ID, {
          ID: props.order.ID,
          note: vm.orderNote,
        });
        await store.dispatch("displayAlert", {
          color: "success",
          message: "Team oppdatert! 🥳",
        });
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: e,
        });
      }
    }

    function getReadableStatus(): string {
      return props.order.invoice?.status === "paid" && props.order.invoice?.amountPaid === 0
        ? "Kreditert"
        : props.order.invoice?.status === "paid"
          ? "Betalt"
          : props.order.invoice?.status === "open" && props.order.invoice?.dueDate < Moment().unix()
            ? "Forfalt"
            : props.order.invoice?.status === "open"
              ? "Ikke betalt"
              : "Ukjent status";
    }

    function getStatusColors(): string {
      return props.order.invoice?.status === "paid" && props.order.invoice?.amountPaid === 0
        ? "grey"
        : props.order.invoice?.status === "paid"
          ? "green"
          : props.order.invoice?.status === "open" && props.order.invoice?.dueDate < Moment().unix()
            ? "red"
            : props.order.invoice?.status === "open"
              ? "goldenrod"
              : "ligt-grey";
    }

    function openInvoiceItem() {
      if (props.order.invoiceID) open(`https://dashboard.stripe.com/invoices/${props.order.invoiceID}`, "_blank");
      else if (props.order.invoiceItemID) open(`https://dashboard.stripe.com/search?query=${props.order.invoiceItemID}`, "_blank");
    }

    return {
      copyToClipboard,
      // courseType,
      create,
      getReadableStatus,
      getStatusColors,
      openEditCustomerDialog,
      openInvoiceItem,
      orderButtonText,
      readableOrderDate,
      updateOrderNote,
      // updateStudentNote,
      vm,
    };
  },
});
