

















































































import CoursePicker from "@/components/courses/CoursePicker.vue";
import OrderCard from "@/components/orders/BalanceOrderCard.vue";
import { Ref, computed, defineComponent, onMounted, provide, reactive, ref, watch } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";
import { query } from "@/api/balance-order-service";

export default defineComponent({
  name: "AllBalanceOrders",
  components: { CoursePicker, OrderCard },
  setup() {
    const offsetPage = ref(0);
    const filters = reactive({
      searchUID: "",
      pakke: [],
      status: "",
    });
    const vm = reactive({
      pakke: ["10", "20", "40", "60"],
      status: [
        { value: "paid", text: "Betalt" },
        { value: "unpaid", text: "Ikke betalt" },
        { value: "past due", text: "Forfalt" },
        { value: "cancelled", text: "Kansellert" },
        { value: "no invoiceItem", text: "Ikke fakturert" },
        { value: "unknown status", text: "Ukjent status" },
      ],
    });

    const ordersPages: Ref<Record<string, Learnlink.BalanceOrder.default.Order[]>> = ref({});
    const orders: Ref<(Learnlink.BalanceOrder.default.Order)[]> = computed(() => {
      const orders: (Learnlink.BalanceOrder.default.Order)[] = [];
      for (const page in ordersPages.value) {
        for (const order of ordersPages.value[page]) {
          orders.push(order);
        }
      }
      return orders;
    });

    const isLoading = ref(false);
    const total = ref(0);
    const currentPromise: Ref<Promise<unknown> | null> = ref(null);
    let requestID = 0;

    const search = async () => {
      isLoading.value = true;

      requestID = Math.random();
      const reference = requestID;

      await new Promise((resolve) => setTimeout(resolve, 100));
      if (requestID !== reference) return;
      const promises: Promise<void>[] = [];

      for (let i = 0; i <= offsetPage.value; i++) {
        if (!ordersPages.value[i.toString()]) {
          ordersPages.value[i.toString()] = [] as Learnlink.BalanceOrder.default.Order[];

          promises.push(
            query({
              customerUID: filters.searchUID,
              pakke: filters.pakke,
              status: filters.status,
              page: offsetPage.value,
            }).then((value) => {
              if (requestID !== reference) return;

              if (total.value !== value.count) {
                total.value = value.count;
              }
              ordersPages.value = {
                ...ordersPages.value,
                [i.toString()]: value.docs,
              };
            })
          );
        }
      }
      if (promises.length) {
        currentPromise.value = Promise.allSettled(promises).then((values) => {
          const rejected = values.filter((value): value is PromiseRejectedResult => value.status === "rejected");

          if (rejected.length) {
            store.dispatch("displayAlert", {
              color: "red",
              title: "Noe gikk galt.",
              message: rejected.map((value) => String(value.reason)).join(", ")
            });
          }
        })
          .finally(() => {
            isLoading.value = reference !== requestID;
          });
      }
      else {
        isLoading.value = false;
      }
    };

    const forceSearch = () => {
      ordersPages.value = {};
      offsetPage.value = 0;
      search();
    };
    const nextPage = () => {
      offsetPage.value += 1;
    };

    onMounted(forceSearch);
    watch(filters, forceSearch);
    watch(offsetPage, search);

    provide("updateBalanceOrder", (uid: string, order: Partial<Learnlink.BalanceOrder.default.Order> | null) => {
      if (!orders.value) return;

      if (order) {
        const index = orders.value.findIndex((o) => o.ID === uid);
        if (index !== undefined && index !== -1) {
          const clone = [...orders.value];
          clone[index] = { ...orders.value[index], ...order };
          orders.value = clone;
        }
      }
      else {
        const index = orders.value.findIndex((o) => o.ID === uid);
        if (index !== undefined && index !== -1) {
          orders.value.splice(index, 1);
        }
      }
    });

    return {
      total,
      isLoading,
      vm,
      forceSearch,
      nextPage,
      orders,
      offsetPage,
      filters,
    };
  },
});
