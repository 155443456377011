

















































import AllCourseOrders from "@/components/orders/AllCourseOrders.vue";
import AllBalanceOrders from "@/components/orders/AllBalanceOrders.vue";
import CreateOrder from "@/components/orders/CreateOrder.vue";
import InvoiceAllNegativeBalances from "@/components/orders/InvoiceAllNegativeBalances.vue";
import NewOrders from "@/components/orders/NewOrders.vue";
import SearchOrders from "@/components/orders/SearchOrders.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Orders",
  components: { InvoiceAllNegativeBalances, SearchOrders, CreateOrder, AllCourseOrders, NewOrders, AllBalanceOrders },
  metaInfo() {
    return {
      title: "Admin / Bestillinger",
    };
  },
});
