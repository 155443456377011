




































import { cancelOrder } from "@/api/balance-order-service";
import store from "@/store/store";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, inject, PropType, reactive, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "CancelBalanceOrderDialog",
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    order: {
      type: Object as PropType<Learnlink.Order.View.Admin.Thumbnail>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });
    const updateOrder = inject<(uid: string, order: null) => void>("updateBalanceOrder");
    const vm = reactive({
      loading: false,
    });

    async function cancel() {
      vm.loading = true;
      try {
        await cancelOrder(props.order.ID, props.order.invoiceItemID);
        await store.dispatch("displayAlert", {
          color: "success",
          message: "Kansellert! 🥳",
        });
        updateOrder && await updateOrder(props.order.ID, null);
        _value.value = false;
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: e,
        });
      }
      vm.loading = false;
    }

    return {
      _value,
      cancel,
      vm,
    };
  },
});
