






























































































































































import { copyToClipboard } from "@/helpers/copyToClipboard";
import { getSeller } from "@/api/seller-service";
import DocumentApproval from "@/components/sellers/SellerCard/switches/DocumentApproval.vue";
import Learnlink from "@learnlink/interfaces";
import IntercomComments from "../core/IntercomComments.vue";
import Moment from "@/services/moment";
import SellerApproval from "@/components/sellers/SellerCard/SellerApproval.vue";
import SellerCalendarDialog from "@/components/sellers/SellerCard/SellerCalendarDialog.vue";
import SellerCardAboutSeller from "@/components/sellers/SellerCard/SellerCardAboutSeller.vue";
import SellerCategoryPicker from "@/components/core/SellerCategoryPicker.vue";
import SellerProjectList from "@/components/sellers/SellerProjectList.vue";
import SendSMS from "../SendSMS.vue";
import UserNote from "../UserNote.vue";
import { computed, defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import handleError from "@/helpers/errors";
import store from "@/store/store";
import WeekdayPicker from "@/components/sellers/SellerCard/WeekdayPicker.vue";
export default defineComponent({
  name: "SellerCard",
  components: {
    SellerCalendarDialog,
    DocumentApproval,
    SellerApproval,
    SellerCardAboutSeller,
    SellerProjectList,
    IntercomComments,
    SendSMS,
    UserNote,
    SellerCategoryPicker,
    WeekdayPicker,
  },
  props: {
    uid: {
      type: String as PropType<string>,
      required: true
    }
  },
  setup(props, { emit }) {
    const vm = reactive({
      loading: true,
      seller: {} as Learnlink.Seller.default,
      sellerCalendarDialogVisible: false,
    });
    const age = computed(() => Moment().diff(vm.seller.account?.birthdate, "years"));

    function close() {
      emit("close");
    }

    async function mount() {
      try {
        vm.seller = await getSeller(props.uid);
      }
      catch (e) {
        handleError(e);
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Klarte ikke å laste Pro",
        });
      }
    }

    onMounted(async () => {
      vm.loading = true;
      await mount();
      vm.loading = false;
    });

    return {
      age,
      close,
      copyToClipboard,
      emit,
      Moment,
      vm,
    };
  },
});
