import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard),(_vm.isLoading)?_c(VRow,{attrs:{"justify":"center"}},[_c(VProgressCircular,{staticClass:"ma-16",attrs:{"color":"primary","indeterminate":""}})],1):(_vm.files && _vm.files.length)?_c(VRow,_vm._l((_vm.files),function(file){return _c(VCol,{key:file.uid},[_c(VCard,[_c(VCardTitle,{staticStyle:{"display":"flex","justify-content":"space-between"}},[_vm._v(" "+_vm._s(file.name)+" "),_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteFileAsync(file)}}},[_vm._v(" Slett ")])],1),_c(VImg,{attrs:{"src":file.url}})],1)],1)}),1):_c(VAlert,{attrs:{"color":"warning","text":"","icon":"mdi-alert-circle"}},[_vm._v(" Fant ingen filer ")]),_c(VAlert,{attrs:{"color":"info","text":"","icon":"mdi-alert-circle"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_vm._v(" Last opp "),_c(VBtn,{attrs:{"color":"secondary","small":""},on:{"click":function($event){_vm.uploadVisible = !_vm.uploadVisible}}},[_vm._v(" Åpne ")])],1)]),(_vm.uploadVisible)?_c('uploader',{attrs:{"collection":_vm.type,"uid":_vm.seller.account.uid},on:{"ok":function($event){return _vm.refresh()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }