

















import { activeCategories } from "@/helpers/categories";
import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";
import { updateProfile } from "@/api/profile-service";

export default defineComponent({
  name: "SellerCategoryPicker",
  props: {
    seller: {
      type: Object as PropType<Learnlink.Seller.default>,
      required: true
    }
  },
  setup(props) {
    const vm = reactive({
      loading: false,
      selection: [] as string[],
    });

    onMounted(() => {
      vm.selection = props.seller.profile.categories;
    });

    async function save() {
      vm.loading = true;

      try {
        const updatedSellerProfile = await updateProfile({
          uid: props.seller.user.uid,
          categories: vm.selection,
        });
        props.seller.profile.categories = updatedSellerProfile.categories;

        store.dispatch("displayAlert", {
          color: "green",
          message: "Oppdatert",
        });
      }
      catch (e) {
        handleError(e);
      }
      vm.loading = false;
    }

    return {
      activeCategories,
      save,
      vm
    };
  },
});
