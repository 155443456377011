import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VToolbar,{staticClass:"mb-6",staticStyle:{"z-index":"100","position":"sticky","top":"20px"},attrs:{"flat":""}},[_c(VSpacer),_c('h2',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.total)+" bestillinger ")]),_c(VBtn,{staticStyle:{"margin-left":"20px"},attrs:{"dark":"","color":"primary","loading":_vm.isLoading},on:{"click":function($event){return _vm.forceSearch()}}},[_c(VIcon,[_vm._v("mdi-refresh")]),_vm._v(" Search ")],1),_c(VSpacer)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c(VSelect,{attrs:{"outlined":"","dense":"","items":_vm.vm.status,"label":"Status","clearable":""},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VSelect,{attrs:{"outlined":"","dense":"","items":_vm.vm.pakke,"label":"Pakke","clearable":"","multiple":""},model:{value:(_vm.filters.pakke),callback:function ($$v) {_vm.$set(_vm.filters, "pakke", $$v)},expression:"filters.pakke"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"Søk etter uid(kunde/order)","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.filters.searchUID && _vm.forceSearch()}},model:{value:(_vm.filters.searchUID),callback:function ($$v) {_vm.$set(_vm.filters, "searchUID", $$v)},expression:"filters.searchUID"}})],1)],1),_vm._l((_vm.orders),function(order,index){return _c('order-card',{key:order.ID,staticClass:"mb-2",attrs:{"order":order,"index":index}})}),_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"center"}},[(_vm.orders.length)?_c(VBtn,{attrs:{"color":"primary","loading":_vm.isLoading},on:{"click":function($event){return _vm.nextPage()}}},[_vm._v(" Flere result ")]):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }