



































































import { deleteFile, listFiles } from "@/api/file-service";
import useAsyncData from "@/helpers/useAsyncData";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import Uploader from "./Uploader.vue";

export default defineComponent({
  name: "FileList",
  components: { Uploader },
  props: {
    seller: {
      type: Object as PropType<Learnlink.Seller.default>,
      required: true,
    },
    type: {
      type: String as PropType<Learnlink.File.DocumentCollectionRef>,
      required: true,
    },
  },
  setup(props) {
    const { isLoading, data: files, refresh } = useAsyncData(() => listFiles(props.type, props.seller.user.uid));
    const uploadVisible = ref(false);

    async function deleteFileAsync(file:Learnlink.File.Reference) {
      await deleteFile(props.type, props.seller.account.uid, file.uid);
      refresh();
    }

    return {
      deleteFileAsync,
      files,
      refresh,
      uploadVisible,
      isLoading,
    };
  },
});
